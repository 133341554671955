//import Web3 from 'web3';
import React from 'react';

//import AbiFactoryPolygon from "./contracts/MyFactoryPolygon.json";
//import AbiFactoryBSC from "./contracts/MyFactoryBSC.json";
//import AbiMyToken from "./contracts/Mytoken.json";
import { useState, useEffect } from "react";
import './App.css';

//import ethereum from './ethereum.png';
//import binance from './binance.svg';
//import update from './update.svg';
//import search from './search.svg';

//import jackpot from './jackpotlogo.png';
import mecenas from './mecenas2.png';
import moby from './ojo500.png';
import gitbook from './gitbook.svg';
import logoponzi from './logoponzipot.png';
import code from './CODE.gif';
import telegram from './telegram.png';

import trust1 from './TRUST1T.png';
import trust2 from './TRUST2T.png';

//import {
  // Button,
  // Input,
//} from 'reactstrap';




function App() {






   // useEffect(() => {
      


     // async function fetchdata() {
        
     
     //}
     //fetchdata();

       
    
     
  //},[]);


  return (

    
<div className="App">    

<div class="container">

<p></p>

<div class="row">
<div class="col">

<p class="text text-start">

<img src={moby} class="img" width="100" height="auto"></img>

</p>

</div>

</div>




<div class="row row-cols-1 row-cols-lg-2 g-1">
  <div class="col">
    <div class="card bg-transparent h-100">
    <div class="card-body bg-transparent">





<h1><p class="text text-start text-light"><b>Let's Build Something Cool Together</b></p></h1>


    <div class="text text-start text-info"><h3>Full Stack Blockchain Developers</h3></div>



<p></p>
<br></br>


      

<p class="text text-start text-light">
Committed to making social change through the use of blockchain technology.
We build decentralized, permission-less, trust-less and non-custodial protocols across chains.
</p>


<br></br>


<p class="text text-start text-light"><h2>Give us a call</h2></p>


<p class="text text-start text-secondary">mobydick.space</p>

<p></p>





<p class="text text-start text-info"><a href="https://t.me/Ahab_Captain" target="_blank" rel="noopener noreferrer"><img src={telegram} class="img-fluid" width="30" height="auto"></img></a> <small>We speak english and spanish</small></p>


<p class="text text-start text-secondary">Email: admin@mobydick.space</p>






</div>
</div>
</div>


<div class="col">


<div class="card bg-transparent h-100">
<div class="card-body bg-transparent">



<div class="text text-center"><img src={code} class="img-fluid" width="300" height="auto"></img></div>




</div>
</div>
</div>
</div>


<p></p>


</div>

</div>





);

  }


export default App;